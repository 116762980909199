<template>
  <div class="section about" id="about-section">
    <div class="content-box">
      <div class="row">
        <div class="col col-m-12 col-t-5 col-d-5">
          <div class="info-list" style="text-align: left;">
            <ul>
              <li>
                <strong><span>{{ data.Age }}</span></strong> 25
              </li>
              <li>
                <strong><span>{{ data.Job }}</span></strong> {{ data.MyJob }}
              </li>
              <li>
                <strong><span>Langage</span></strong> {{ data.LangageSpeaked }}
              </li>
              <li>
                <strong><span>Localisation</span></strong> Paris, France
              </li>
              <li>
                <strong><span>{{ data.Phone }}</span></strong>
                <a href="tel:+33667267808">+33 6 67 26 78 08</a>
              </li>
              <li>
                <strong><span>E-mail</span></strong>
                <a href="mailto:enzobijaoui@hotmail.com">enzobijaoui@hotmail.com</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col col-m-12 col-t-7 col-d-7" style="text-align: left;">
          <div class="text-box">
            <p><strong>{{ data.AboutTitle }}</strong></p>
            <p v-html="data.AboutDescription"></p>
          </div>
          <div class="bts">
            <a :href="cvUrl" class="btn btn_animated" download><span class="circle">{{ data.DownloadCv }}</span></a>
            <a href="#" class="btn extra contact-btn btn_animated"><span class="circle">{{ data.ContactMe }}</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutSection",
  data() {
    return {
      data: {}
    };
  },
  computed: {
    cvUrl() {
      return this.$i18n.locale === 'fr'
        ? '/cv/cv_Enzo_Bijaoui_fr.pdf'
        : '/cv/cv_Enzo_Bijaoui_en.pdf';
    }
  },
  created() {
    this.loadAboutData(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.loadAboutData(newLocale);
    }
  },
  methods: {
    async loadAboutData(locale) {
      if (locale === 'fr') {
        this.data = (await import('../json/about/about-fr.json')).default;
      } else {
        this.data = (await import('../json/about/about-en.json')).default;
      }
    }
  }
};
</script>
