import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';

// Importation des fichiers CSS globaux depuis le dossier public
import '../public/css/animate.css';
import '../public/css/basic.css';
import '../public/css/ionicons.css';
import '../public/css/layout.css';
import '../public/css/magnific-popup.css';
import '../public/css/owl.carousel.css';
import '../public/less/basic.less';
import '../public/less/layout.less';
import '../public/less/setting.less';


const app = createApp(App);

app.use(i18n);

app.mount('#app');
