<template>
  <div class="fixed-buttons">
    <button
      class="button-56"
      @click="changeLanguage('en')"
      v-if="currentLanguage === 'fr'"
    >
      English
    </button>
    <div></div>
    <button
      class="button-56"
      @click="changeLanguage('fr')"
      v-if="currentLanguage === 'en'"
    >
      Français
    </button>
  </div>
  <div class="page" id="home-section">
    <!-- Preloader -->
    <div class="preloader">
      <div class="centrize full-width">
        <div class="vertical-center">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Started Background -->
    <div class="started-bg">
      <div
        class="slide"
        style="background-image: url(images/slide-bg.jpg)"
      ></div>
    </div>

    <!-- Header -->
    <header>
      <div class="top-menu">
        <ul>
          <li class="active">
            <a class="btn_animated" href="#home-section"
              ><span class="circle">{{ headerData.Home }}</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#about-section"
              ><span class="circle">{{ headerData.About }}</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#skills-section"
              ><span class="circle">{{ headerData.Skills }}</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#experience-section"
              ><span class="circle">{{ headerData.Experience }}</span></a
            >
          </li>
          <!-- <li>
            <a class="btn_animated" href="#service-section"
              ><span class="circle">Services</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#education-section"
              ><span class="circle">Education</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#works-section"
              ><span class="circle">Portfolio</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#clients-section"
              ><span class="circle">Clients</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#pricing-section"
              ><span class="circle">Pricing</span></a
            >
          </li>
          <li>
            <a class="btn_animated" href="#blog-section"
              ><span class="circle">Blog</span></a
            >
          </li> -->
          <li>
            <a class="btn_animated" href="#contact-section"
              ><span class="circle">{{ headerData.Contact }}</span></a
            >
          </li>
        </ul>
        <a href="#" class="menu-btn" @click.prevent="toggleMenu"
          ><span></span
        ></a>
      </div>
    </header>

    <!-- Container -->
    <div class="container">
      <!-- Started -->
      <div class="section started">
        <div class="st-box">
          <div class="st-bts">
            <a href="mailto:enzobijaoui@hotmail.com" class="btn_animated">
              <span class="circle"><i class="icon ion ion-plus"></i></span>
            </a>
          </div>
          <div class="st-image"><img src="images/man.png" alt="" /></div>
          <div class="st-title">Enzo Bijaoui</div>
          <div class="st-subtitle">{{ $t("JobTitle") }}</div>
          <div class="st-soc">
            <a
              target="blank"
              href="https://fr.linkedin.com/in/enzo-bijaoui-8b57b2149"
              class="btn_animated"
            >
              <span class="circle"
                ><i class="icon ion ion-social-linkedin"></i
              ></span>
            </a>
            <a
              target="blank"
              href="https://github.com/en5b"
              class="btn_animated"
            >
              <span class="circle"
                ><i class="icon ion ion-social-github"></i
              ></span>
            </a>
            <a target="blank" href="https://ensb.fr/" class="btn_animated">
              <span class="circle"><i class="icon ion ion-ios-world"></i></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSection",
  data() {
    return {
      isMenuOpen: false,
      currentLanguage: this.$i18n.locale,
      headerData: {},
    };
  },
  watch: {
      "$i18n.locale"(newLocale) {
        this.loadheaderData(newLocale);
      },
  },
  created() {
      this.loadheaderData(this.$i18n.locale);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.currentLanguage = lang;
    },
    async loadheaderData(locale) {
      if (locale === "fr") {
        this.headerData = (
          await import("../json/header/header-fr.json")
        ).default;
      } else {
        this.headerData = (
          await import("../json/header/header-en.json")
        ).default;
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez vos styles spécifiques ici */
.top-menu.menu-open ul {
  display: block;
  /* autres styles pour afficher le menu */
}
</style>
