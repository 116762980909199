<template>
  <div class="section skills" id="skills-section">
    <div class="title">Skills</div>
    <div class="row">
      <div class="col col-m-12 col-t-6 col-d-6">
        <div class="content-box animated">
          <div class="i_title">
            <div class="icon"><i class="icon ion ion-gear-b"></i></div>
            <div class="name">{{ skills.ProfessionnalSkills }}</div>
          </div>
          <div class="skills">
            <ul>
              <li v-for="(skill, index) in skills.ProfessionalSkillsList" :key="index">
                <div class="name">{{ skill.Name }}</div>
                <div class="progress">
                  <div class="percentage" :style="{ width: skill.Pourcentage + '%' }">
                    <span class="percent"><i class="icon ion ion-ios-checkmark-empty"></i></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col col-m-12 col-t-6 col-d-6">
        <div class="content-box animated">
          <div class="i_title">
            <div class="icon"><i class="icon ion ion-person"></i></div>
            <div class="name">{{ skills.PersonalSkills }}</div>
          </div>
          <div class="skills">
            <ul>
              <li v-for="(skill, index) in skills.PersonalSkillsList" :key="index">
                <div class="name">{{ skill.Name }}</div>
                <div class="progress">
                  <div class="percentage" :style="{ width: skill.Pourcentage + '%' }">
                    <span class="percent"><i class="icon ion ion-ios-checkmark-empty"></i></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillsSection',
  data() {
    return {
      skills: {}
    };
  },
  created() {
    this.loadSkillsData(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.loadSkillsData(newLocale);
    }
  },
  methods: {
    async loadSkillsData(locale) {
      if (locale === 'fr') {
        this.skills = (await import('../json/skills/skills-fr.json')).default.Skills;
      } else {
        this.skills = (await import('../json/skills/skills-en.json')).default.Skills;
      }
    }
  }
};
</script>

<style scoped>
/* Ajoutez vos styles spécifiques ici */
</style>
