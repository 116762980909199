<template>
  <div id="toast-container" class="toast-container">
    <div v-for="(toast, index) in toasts" :key="index" :class="`toast ${toast.type}`">
      <div class="toast-status-icon">
        <svg>
          <use :xlink:href="`#${toast.icon}`"></use>
        </svg>
      </div>
      <div class="toast-content">
        <span>{{ contactData.SuccessTitle }}</span>
        <p>{{ contactData.SuccessMessage }}</p>
      </div>
      <button class="toast-close" @click="closeToast(index)">
        <svg>
          <use xlink:href="#closeToastIcon"></use>
        </svg>
      </button>
      <div class="toast-duration"></div>
    </div>

    <!-- Inline SVG Symbols -->
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none">
      <symbol id="successToastIcon" viewBox="0 0 512 512">
        <path
          fill="var(--color-status)"
          d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
        />
      </symbol>
      <symbol id="closeToastIcon" viewBox="0 0 384 512">
        <path
          fill="var(--color-status)"
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        />
      </symbol>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
      contactData: {},
      toasts: [
        {
          type: "success",
          icon: "successToastIcon",
        },
      ],
    };
  },
  mounted() {
    this.autoRemoveToasts();
    
  },
  created() {
    this.loadContactData(this.$i18n.locale);
  },
  watch: {
    "$i18n.locale"(newLocale) {
      this.loadContactData(newLocale);
    },
  },
  methods: {
    async loadContactData(locale) {
      if (locale === "fr") {
        this.contactData = (await import("../../json/contact/contact-fr.json")).default;
      } else {
        this.contactData = (await import("../../json/contact/contact-en.json")).default;
      }
    },
    closeToast(index) {
      this.toasts.splice(index, 1);
    },
    autoRemoveToasts() {
      setTimeout(() => {
        this.toasts = [];
      }, 10000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url("../../assets/variables.css");

@keyframes toastDuration {
    from { width: 0%; }
    to { Width: 100%; }
  }
  .toast-container {
      position: fixed;
      top: var(--distance-15);
      right: 80px;
      z-index: 8888;
      display: block;
      width: auto;
  }
  .toast {
      display: block;
      width: 100%;
      min-height: var(--distance-375);
      margin-bottom: var(--distance-1);
      border-radius: var(--border-radius);
      box-shadow: var(--control-box-shadow);
      background-color: var(--color-greyscale-10);
      overflow: hidden;
      --color-status: var(--color-greyscale-4);
      svg {
          
      }
      .toast-status-icon {
          display: block;
          float: left;
          height: var(--distance-375);
          width: var(--distance-3);
          padding: var(--distance-1) 0 var(--distance-1) var(--distance-05);
          svg {
              display: block;
              width: 100%;
              height: 100%;
          }
      }
      .toast-content {
          display: block;
          float: left;
          width: calc(100% - var(--distance-575));
          padding: var(--distance-075) var(--distance-1) var(--distance-075) var(--distance-05);
          line-height: var(--line-height-s);
          span {
              font-size: var(--font-size-s);
              font-weight: var(--font-weight-bold);
              color: var(--color-greyscale-4);
              line-height: inherit;
          }
          p {
              font-size: var(--font-size-xs);
              color: var(--color-greyscale-2);
              line-height: inherit;
              margin-top: 1px;
          }
      }
      .toast-close {
          display: block;
          float: right;
          clear: right;
          width: var(--distance-225);
          height: var(--distance-225);
          margin-top: var(--distance-025);
          margin-right: var(--distance-05);
          padding: var(--distance-05);
          background: transparent;
          background-color: transparent;
          svg {
              display: block;
              width: 100%;
              height: 100%;
              margin: {
                  right: 0;
                  left: 0;
              }
              transform: none;
          }
      }
      .toast-duration {
          position: relative;
          display: block;
          float: none;
          clear: both;
          height: 3px;
          width: 100%;
          background-color: var(--color-greyscale-8);
          &::after {
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 0%;
              height: inherit;
              background-color: var(--color-greyscale-5);
              animation-name: toastDuration;
              animation-duration: 10s;
              animation-timing-function: linear;
          }
      }
      &.info {
          background: var(--color-info-10);
          --color-status: var(--color-info-4);
          svg {
              --color-status: var(--color-info-4);
          }
          span {
              color: var(--color-status);
          }
          p {
              color: var(--color-info-2);
          }
          .toast-duration {
              background-color: var(--color-info-8);
              &::after {
                  background-color: var(--color-info-4);
              }
          }
      }
      &.success {
          background: var(--color-success-10);
          --color-status: var(--color-success-3);
          svg {
              --color-status: var(--color-success-3);
          }
          span {
              color: var(--color-status);
          }
          p {
              color: var(--color-success-2);
          }
          .toast-duration {
              background-color: var(--color-success-8);
              &::after {
                  background-color: var(--color-success-3);
              }
          }
      }
      &.alert {
          background: var(--color-alert-10);
          --color-status: var(--color-alert-4);
          svg {
              --color-status: var(--color-alert-4);
          }
          span {
              color: var(--color-status);
          }
          p {
              color: var(--color-alert-2);
          }
          .toast-duration {
              background-color: var(--color-alert-8);
              &::after {
                  background-color: var(--color-alert-4);
              }
          }
      }
      &.error {
          background: var(--color-error-10);
          --color-status: var(--color-error-4);
          svg {
              --color-status: var(--color-error-4);
          }
          span {
              color: var(--color-status);
          }
          p {
              color: var(--color-error-2);
          }
          .toast-duration {
              background-color: var(--color-error-8);
              &::after {
                  background-color: var(--color-error-4);
              }
          }
      }
  }

</style>
