// src/i18n.js

import { createI18n } from 'vue-i18n';

// Import the translation files
import en from './locales/en/en.json';
import fr from './locales/fr/fr.json';

const messages = {
  en,
  fr,
};

const i18n = createI18n({
  locale: 'en', // Set the default locale
  fallbackLocale: 'en', // Set the fallback locale
  messages, // Set locale messages
});

export default i18n;
