<template>
  <div class="section contacts" id="contact-section">
    <div class="title">{{ contactData.Title }}</div>
    <div class="row">
      <div class="col col-m-12 col-t-6 col-d-6">
        <div class="content-box animated">
          <div class="info-list">
            <ul>
              <li>
                <strong
                  ><span>{{ $t("Address") }}</span></strong
                >
                {{ contactData.Address }}
              </li>
              <li>
                <strong
                  ><span>{{ $t("Phone") }}</span></strong
                >
                <a :href="'tel:' + contactData.Phone">{{ contactData.Phone }}</a>
              </li>
              <li>
                <strong><span>E-mail:</span></strong>
                <a :href="'mailto:' + contactData.Email">{{ contactData.Email }}</a>
              </li>
            </ul>
          </div>
          <div class="map" id="map"></div>
        </div>
      </div>
      <div class="col col-m-12 col-t-6 col-d-6">
        <div class="content-box animated">
          <h4>{{ contactData.WriteMessage }}</h4>
          <div class="contact-form">
            <form @submit.prevent="submitForm">
              <div class="group-val">
                <input
                  type="text"
                  v-model="form.name"
                  :placeholder="contactData.YourName"
                />
              </div>
              <div class="group-val">
                <input
                  type="email"
                  v-model="form.email"
                  :placeholder="contactData.YourEmail"
                />
              </div>
              <div class="group-val">
                <input
                  type="text"
                  v-model="form.subject"
                  :placeholder="contactData.Subject"
                />
              </div>
              <div class="group-val ct-gr">
                <textarea
                  v-model="form.message"
                  :placeholder="contactData.Message"
                ></textarea>
              </div>
              <button type="submit" class="btn btn_animated">
                <span class="circle">{{ contactData.SendMessage }}</span>
              </button>
            </form>
            <!-- <div v-if="successMessage" class="alert-success">
              <p>{{ contactData.SuccessMessage }}</p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastSuccess v-if="successMessage"/>
</template>

<script>
import ToastSuccess from "./Toasts/ToastSuccess.vue";
export default {
  name: "ContactSection",
  components: {
    ToastSuccess,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      successMessage: false,
      contactData: {},
      siteKey: "6LdfDwwqAAAAALH01jMecqy1ncHAsRGClQsn1gP2", // Remplacez par votre clé de site reCAPTCHA v3
    };
  },
  created() {
    this.loadContactData(this.$i18n.locale);
  },
  watch: {
    "$i18n.locale"(newLocale) {
      this.loadContactData(newLocale);
    },
  },
  methods: {
    async loadContactData(locale) {
      if (locale === "fr") {
        this.contactData = (await import("../json/contact/contact-fr.json")).default;
      } else {
        this.contactData = (await import("../json/contact/contact-en.json")).default;
      }
    },
    async submitForm() {
      try {
        const formData = {
          name: this.form.name,
          email: this.form.email,
          subject: this.form.subject,
          message: this.form.message,
        };
        JSON.stringify(formData);

        const response = await fetch(
          "https://script.google.com/macros/s/AKfycbxLMzn7ePOFVkIFEhB-UPFvMjzDkyD0_662Av8LHf_swdB8WRzNCjPE4QaycTi9Ed8r/exec",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(formData),
          }
        );

        if (response.ok) {
          this.successMessage = true;
          console.log(response);
          // Réinitialiser le formulaire
          this.form.name = "";
          this.form.email = "";
          this.form.subject = "";
          this.form.message = "";
        } else {
          alert("Il y a eu une erreur lors de l'envoi du message.");
        }
      } catch (error) {
        alert("Il y a eu une erreur lors de l'envoi du message.");
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez vos styles spécifiques ici */
</style>
