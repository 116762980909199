<template>
  <div class="section experience" id="experience-section">
    <div class="title">
      {{ ExperienceData.Title }}
      <span class="circle"><i :class="ExperienceData.Icone"></i></span>
    </div>
    <div class="cd-timeline">
      <div
        v-for="(edu, index) in ExperienceData.ExperienceList"
        :key="index"
        class="cd-timeline-block animated"
      >
        <div class="cd-timeline-point">
          <i class="icon ion ion-ios-checkmark-empty"></i>
        </div>
        <div class="cd-timeline-content">
          <div class="content-box">
            <div class="date">{{ edu.Date }}</div>
            <div class="name">{{ edu.Name }}</div>
            <div class="category">{{ edu.Tag }}</div>
            <p v-html="edu.Description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExperienceSection',
  data() {
    return {
      ExperienceData: {}
    };
  },
  created() {
    this.loadExperienceData(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.loadExperienceData(newLocale);
    }
  },
  methods: {
    async loadExperienceData(locale) {
      if (locale === 'fr') {
        this.ExperienceData = (await import('../json/experience/experience-fr.json')).default;
      } else {
        this.ExperienceData = (await import('../json/experience/experience-en.json')).default;
      }
    }
  }
};
</script>

<style scoped>
/* Ajoutez vos styles spécifiques ici */
</style>
