<template>
  <div class="section experience" id="experience-section">
    <div class="title">
      {{ educationData.Title }}
      <span class="circle"><i :class="educationData.Icone"></i></span>
    </div>
    <div class="cd-timeline">
      <div
        v-for="(edu, index) in educationData.EducationList"
        :key="index"
        class="cd-timeline-block animated"
      >
        <div class="cd-timeline-point">
          <i class="icon ion ion-ios-checkmark-empty"></i>
        </div>
        <div class="cd-timeline-content">
          <div class="content-box">
            <div class="date">{{ edu.Date }}</div>
            <div class="name">{{ edu.Name }}</div>
            <div class="category">{{ edu.Tag }}</div>
            <p>
              {{ edu.Description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EducationSection',
  data() {
    return {
      educationData: {}
    };
  },
  created() {
    this.loadEducationData(this.$i18n.locale);
  },
  watch: {
    '$i18n.locale'(newLocale) {
      this.loadEducationData(newLocale);
    }
  },
  methods: {
    async loadEducationData(locale) {
      if (locale === 'fr') {
        this.educationData = (await import('../json/education/education-fr.json')).default;
      } else {
        this.educationData = (await import('../json/education/education-en.json')).default;
      }
    }
  }
};
</script>

<style scoped>
/* Ajoutez vos styles spécifiques ici */
</style>
