<template>
  <Header />
  <div class="container">
    <div class="wrapper">
      <About />
      <Skills />
      <Education />
      <Experience />
      <!-- <Services /> -->
      <!-- <Portfolio /> -->
      <Contact />
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import About from "./components/About.vue";
import Skills from "./components/Skills.vue";
import Education from "./components/Education.vue";
import Experience from "./components/Experience.vue";
// import Services from "./components/Services.vue";
// import Portfolio from "./components/Portfolio.vue";
import Footer from "./components/Footer.vue";
import Contact from "./components/Contact.vue";

export default {
  name: "App",
  components: {
    Header,
    About,
    Skills,
    Education,
    Experience,
    // Services,
    // Portfolio,
    Footer,
    Contact,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
